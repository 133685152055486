import React from "react"
import { Container }  from '@material-ui/core'

import SEO from "../components/theme/seo"
import Layout from "../components/theme/layout"
import Masthead from "../components/theme/masthead"
import OneCol from '../components/one-col'
import Map from "../components/map/index"

import ContactBanner from "../images/contact-banner.jpg"
import Phone from "../images/svg/imgphone.svg"
import Clock from "../images/svg/imgclock.svg"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
        <Masthead
            title="Contact Us"
            img={ContactBanner}
        />
        <Container>
            <OneCol
                title="Come Visit Us Today"
                description="At JJ Automotive Insight, you are more than just a valued customer – you’re a friend."
            />
        </Container>
        <div className="border-t-2 mb-20 md:mb-0 border-gray-300 flex flex-wrap">
            <div className="bc-one w-full md:w-3/6 pt-20 md:p-32 inline-flex flex-col items-center text-center border-solid md:border-r-2 border-gray-300">
                <img src={Phone} alt="Phone" />
                <h4 className="text-blue-700">Reach Us</h4>
                <p><a href="https://www.google.com/maps/dir//850+Mosley+St,+Wasaga+Beach,+ON+L9Z+2H4/@44.5013529,-80.0436741,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882a813ac5a55999:0x54f964a321f6f380!2m2!1d-80.0414854!2d44.5013529" target="_blank" rel="noreferrer">850 Mosley Street, Wasaga Beach ON</a><br />
                    <a href="tel:7054222111">705-422-2111 (Office)</a><br />
                    <a href="tel:7055002925">705-500-2925 (24 Hour Emergency Contact)</a>
                </p>
            </div>
            <div className="bc-two w-full md:w-3/6 pt-20 md:p-32 inline-flex flex-col items-center text-center md:border-solid border-gray-300">
                <img src={Clock} alt="Clock" />                
                <h4 className="text-blue-700">Our Hours</h4>
                <p>
                    8:00 a.m. to 6:00 p.m. Monday to Friday<br/>
                    Saturday - by appointment only<br/>
                    Closed Sunday
                </p>
            </div>
        </div>    
        <Map />
    </Layout>
)

export default Contact